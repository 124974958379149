import React from 'react';
import Image, { FixedObject } from 'gatsby-image';

import '../css/global.css';
import { graphql, useStaticQuery } from 'gatsby';
import { BlogAuthorDetails } from './BlogAuthorDetails';


const classNames = {
  backgroundBleed: [
    'mt-8 pb-8',
    'bg-gray-100',
  ].join(' '),
  container: [
    // contentGutterClasses,
    'py-8 clearfix',
    // '',
    'max-w-xl',
    'mx-auto',
    'px-4 md:px-12 xl:px-16',
    'border-t-8 border-gray-500',
  ].join(' '),
  image: [
    'w-24 sm:w-32 md:w-40 lg:w-48',
    'float-left',
    'shadow',
    'mr-4 sm:mr-8',
    'mb-4 sm:mb-8',
  ].join(' '),
};

// todo: extract the image into a faster loader
// todo: social links

type BlogAuthorBlurbProps = {
  author: BlogAuthorDetails;
}

type ProfileStaticQuery = {
  avatar: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
};

const BlogAuthorBlurb: React.FunctionComponent<BlogAuthorBlurbProps> = (props) => {
  const { author } = props;

  const data: ProfileStaticQuery = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/me-landscape.jpeg/" }) {
        childImageSharp {
          fixed(width: 192) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <footer className={classNames.backgroundBleed}>
      <div className="container mx-auto">
        <div className={classNames.container}>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            className={classNames.image}
            alt={author.name}
          />
          <div>
            <div className="font-extrabold leading-relaxed">{author.name}</div>
            <p className="leading-tight">{author.shortDescription}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BlogAuthorBlurb;
