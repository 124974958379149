import React from 'react';

import '../css/global.css';

type BlogArticleProps = {
  size: 'small' | 'medium' | 'large' | 'bleed';
}

const classNameLookup = {
  small: [
    'my-4 w-full',
    'sm:float-right sm:ml-8 md:ml-12',
    'sm:max-w-xs',
  ].join(' '),
  medium: [
    'my-4 w-full',
    'md:float-right md:ml-8 lg:ml-12',
    'md:max-w-sm lg:max-w-md',
  ].join(' '),
  large: [
    'my-4 w-full',
    'lg:float-right lg:ml-8 xl:ml-12',
    'lg:max-w-xl xl:max-w-2xl',
  ].join(' '),
  bleed: [
    'my-8 mx-auto max-w-full',
  ].join(' '),
};

const BlogArticleGutter: React.FunctionComponent<BlogArticleProps> = (props) => (
  <div className={classNameLookup[props.size]} style={{ clear: 'right' }}>
      {props.children}
  </div>
);

export default BlogArticleGutter;
