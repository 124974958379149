import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { FluidObject } from 'gatsby-image';

import { DesignSystemMdxComponents } from '../layout/DesignSystem';
import BlogDesignSystem from './BlogDesignSystem';
import BlogArticleHero from './BlogArticleHero';
import BlogAuthorBlurb from './BlogAuthorBlurb';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import BlogArticleGutter from './BlogArticleGutter';
import Seo from '../components/Seo';
import { BlogAuthorDetails } from './BlogAuthorDetails';
import AttributionLink, { AttributionLinkProps } from '../components/AttributionLink';


type BlogPostTemplateProps = {
  title: string;
  featuredImage?: FluidObject;
  featuredAttribution?: AttributionLinkProps;
  publishedDateDisplay: string;
  publishedDateIso8601: string;
  description: string;
  excerpt: string;
  body: string;

  author: BlogAuthorDetails;
}

const components = {
  ...DesignSystemMdxComponents,
  wrapper: BlogDesignSystem.Wrapper,

  Gutter: BlogArticleGutter,
  Attribution: AttributionLink,
};

const articleBodyClassNames = [
  'container mx-auto',
  'mt-8 md:mt-20',
].join(' ');

// todo: seo

const BlogPostTemplate: React.FunctionComponent<BlogPostTemplateProps> = (props) => (
  <div className="font-sans text-base">
    <Seo
      title={props.title}
      description={props.description || props.excerpt}
      authorName={props.author.name}
    />

    <Header />

    <main>
      <article className="clearfix">
        <BlogArticleHero
          title={props.title}
          publishedDateDisplay={props.publishedDateDisplay}
          publishedDateIso8601={props.publishedDateIso8601}
          featuredImage={props.featuredImage}
          featuredAttribution={props.featuredAttribution}
          author={props.author}
        />

        <div className={articleBodyClassNames}>
          <MDXProvider components={components}>
            <MDXRenderer>{props.body}</MDXRenderer>
          </MDXProvider>
        </div>

      </article>

      <BlogAuthorBlurb author={props.author}/>
    </main>

    <Footer/>
  </div>
);

export default BlogPostTemplate;
