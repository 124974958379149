import React from 'react';
import Img, { FluidObject } from 'gatsby-image';

import '../css/global.css';
import { Link } from 'gatsby';
import { BlogAuthorDetails } from './BlogAuthorDetails';
import AttributionLink, { AttributionLinkProps } from '../components/AttributionLink';

// type TagLinkProps = {
//   text: string;
//   href: string;
// }

// const tagLinkClassNames = [
//   'p-2 my-1 mr-2',
//   'shadow rounded',
//   'leading-none no-underline',
//   'text-grey-900 hover:text-white',
//   'bg-white  hover:bg-black',
// ].join(' ');
//
// const TagLink: React.FunctionComponent<TagLinkProps> = (props) => (
//   <a href={props.href} className={tagLinkClassNames}>{props.text}</a>
// );

type BlogArticleHeroProps = {
  title: string;
  publishedDateDisplay: string;
  publishedDateIso8601: string;
  featuredImage?: FluidObject;
  featuredAttribution?: AttributionLinkProps;
  author: BlogAuthorDetails;
}

const headingElementClasses = [
  'font-serif',
  'my-4 leading-none',
  'font-bold md:font-extrabold',
  'text-3xl md:text-4xl lg:text-5xl',
].join(' ');

const authorElementLinkClasses = [
  'no-underline',
  'border-b-2 border-dotted',
  'border-grey-800 hover:border-black',
  'text-grey-800 hover:text-black',
].join(' ');

const leadingImageElementClasses = [
  'w-full',
  'max-auto md:max-w-sm lg:max-w-lg',
  'mt-8 md:mt-0',
  'md:pl-8',
  'mx-auto',
].join(' ');

// todo: kill the static tags

const BlogArticleHero: React.FunctionComponent<BlogArticleHeroProps> = (props) => {
  // const tags = [
  //   // { text: 'Introduction', href: '#' },
  //   // { text: 'Tag 2', href: '#' },
  //   // { text: 'Another tag', href: '#' },
  // ].map((p) => <TagLink key={p.text} {...p}/>);
  const tags = null;

  const tagNavigationElement = <nav className="justify-start flex flex-wrap text-xs">
    {tags}
  </nav>;

  const headingElement = <h1 className={headingElementClasses}>{props.title}</h1>;

  const { author } = props;
  const authorElement = <>
    by <Link to={author.profilePage} className={authorElementLinkClasses}>{author.name}</Link>
  </>;

  const dateStampElement = (
    <span className="whitespace-no-wrap">
      on <time dateTime={props.publishedDateIso8601}>{props.publishedDateDisplay}</time>
    </span>
  );

  const featureAttributionElement = props.featuredAttribution
    && <AttributionLink {...props.featuredAttribution}/>;
  const featuredImage = props.featuredImage
    && <div className={leadingImageElementClasses}>
      <Img fluid={props.featuredImage} className="block md:rounded-lg shadow-lg"/>
      {featureAttributionElement}
    </div>;

  return <header className="bg-gray-100">
    <div className="container mx-auto pt-4 pb-20 md:pt-16 flex md:items-center flex-col-reverse md:flex-row">

      {featuredImage}

      <div className="px-4 sm:px-8 flex-1">
        {tagNavigationElement}
        {headingElement}

        <div className="my-4 leading-normal">
          {authorElement} {dateStampElement}
        </div>

      </div>
    </div>
  </header>;
};

export default BlogArticleHero;
