import React from 'react';

const classNames = {
  byLine: 'text-grey-700 text-xs my-2',
  link: [
    'no-underline',
    'border-b-2 border-dotted',
    'border-grey-800 hover:border-black',
    'text-grey-800 hover:text-black',
  ].join(' '),
};

export interface AttributionLinkProps {
  name: string;
  url: string;
}

const AttributionLink: React.FunctionComponent<AttributionLinkProps> = (props) => (
  <div className={classNames.byLine}>
    Photo by <a href={props.url} className={classNames.link}
                target="_blank" rel="noopener noreferrer">
    {props.name}</a>
  </div>
);

export default AttributionLink;
