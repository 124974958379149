import React from 'react';

import { ClassNameDecorator } from '../layout/DesignSystem';

export const contentGutterClasses = [
  'max-w-md md:max-w-lg lg:max-w-xl',
  'mx-4 sm:mx-8 md:mx-24 xl:mx-32 xl:mx-56',
].join(' ');

const Wrapper: React.FunctionComponent = (props) => {
  const mdxTopLevel: ClassNameDecorator = { className: contentGutterClasses };
  const extendedChildren = React.Children.map(
    props.children,
    (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement<unknown>, mdxTopLevel);
      }

      return child;
    },
  );
  return <>{extendedChildren}</>;
};

const BlogDesignSystem = {
  Wrapper,
};

export default BlogDesignSystem;
