import React from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import BlogPostTemplate from './BlogPostTemplate';
import { BlogAuthorDetails } from './BlogAuthorDetails';
import { AttributionLinkProps } from '../components/AttributionLink';

const BlogPostTemplateGatsbyComponent:
  React.FunctionComponent<BlogPostTemplateGatsbyComponentProps> = (props) => {
    const author: BlogAuthorDetails = {
      name: 'Justin Lovell',
      shortDescription: 'Full stack engineer; dev-ops promoter and enabler; quality engineering; plus presenter',
      profilePage: '/',
    };

    return <BlogPostTemplate
      {...props.data.mdx.frontmatter}
      {...props.data.mdx}
      featuredImage={props.data.mdx.frontmatter.featuredImageSharp?.childImageSharp?.fluid}
      featuredAttribution={props.data.mdx.frontmatter.featuredAttribution}
      author={author}
    />;
  };

export default BlogPostTemplateGatsbyComponent;

type BlogPostTemplateGatsbyComponentProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        publishedDateIso8601: string;
        publishedDateDisplay: string;
        description: string;

        featuredAttribution?: AttributionLinkProps;
        featuredImageSharp?: {
          childImageSharp? : {
            fluid?: FluidObject;
          };
        };
      };

      excerpt: string;
      body: string;
    };
  };
}

// todo: question: why do we have a pure length of 160?

export const pageQuery = graphql`
  query BlogPostBySlug2($postEdgeSlug: String!) {
    mdx(fields: { slug: { eq: $postEdgeSlug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title

        featuredAttribution {
          name
          url
        }
        featuredImageSharp: featuredImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        publishedDateDisplay: date(formatString: "DD MMMM YYYY")
        publishedDateIso8601: date
        description

      }
    }
  }
`;
